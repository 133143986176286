import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import LayoutNoSidebar from "../components/LayoutNoSidebar";
import Content, { HTMLContent } from "../components/Content";

export const CMSHelpPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h1 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h1>
              <PageContent className="content" content={content} />
            </div>
            <div className="section">
              <h1>Header 1</h1>
              <h2>Header 2</h2>
              <h3>Header 3</h3>
              <h4>Header 4</h4>
              <h5>Header 5</h5>
              <h6>Header 6</h6>
            </div>
            <div className="section">
              <h1>Markdown Cheatsheet</h1>
              <br />
              <h5>HMTL</h5>
              <p>To enter HTML, type it as you would inside the markdown tab inside
              the CMS.</p>
              <h5>Ordered and Unordered Lists</h5>
              <p>To create ordered (numbered) and unordered (bulleted) lists, use the following syntax:</p>
              <br />
              <div className="row">
                <div className="col">
                  <h6>Ordered Lists</h6>
                  <p>1. Item 1</p>
                  <p>2. Item 2</p>
                  <p>3. Item 3</p>
                  <p>4. Item 4</p>
                </div>
                <div className="col">
                  <h6>Unordered Lists</h6>
                  <p>- Item 1</p>
                  <p>- Item 2</p>
                  <p>- Item 3</p>
                  <p>- Item 4</p>
                </div>
              </div>
              <br />
              <h5>Code</h5>
              <p>To create code in the markdown editor, use the following syntax:</p>
              <p>```<br/><code>Enter your code here</code><br/>```</p>
              <br />
              <h5>Links</h5>
              <p>To create a link in the markdown editor, use the following syntax:</p>
              <code>
                [I'm an inline-style link](https://www.google.com)<br/><br/>
                [I'm an inline-style link with title](https://www.google.com "Google's Homepage")
              </code>
              <br />
              <br />
              <br />
              <h5>Horizontal Divider</h5>
              <p>To create a horizontal in the markdown editor, use the following syntax:</p>
              <code>
                ---
                <br />
              </code>
                or
                <br />
              <code>
                ***
                <br />
              </code>
                or
                <br />
              <code>
                ___
              </code>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

CMSHelpPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const CMSHelpPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <LayoutNoSidebar>
      <CMSHelpPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </LayoutNoSidebar>
  );
};

CMSHelpPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default CMSHelpPage;

export const cmsHelpPageQuery = graphql`
  query CMSHelpPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
